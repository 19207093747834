.ben-logo {
  display: inline-block;
  vertical-align: top;

  img {
    vertical-align: top;

    @media screen and (max-width: 850px) and (min-width: 768px) {
      width: 190px;
    }
  }
}
