@import "common/assets/styles/base/colors";

.ben-upload-document {
  display: flex;

  &.ben-uploading {
    .ben-upload-link {
      display: none;
    }
    &.ben-dragged-content {
      display: none;
    }
  }

  .ant-upload-select {
    width: 100%;
  }

  .ant-upload {
    order: 2;
  }

  .ant-upload-list-item-progress,
  .ant-upload-list-item-info,
  .ant-upload-list-item-done,
  .ant-upload-list-item-error {
    display: none;
  }

  .ben-upload-link {
    line-height: 20px;

    &.ben-dragged-trigger {
      width: 100%;
      padding: 16px 0;
    }
  }
}
