.ant-tabs-tab {
  color: $primary-color;
}

.ant-tabs-nav {
  .ant-tabs-tab {
    @include transition;
    display: inline-flex;
    align-items: center;
    padding: 0 9px;
    margin-right: 5px;
    line-height: 44px;
    border-bottom: 3px solid transparent;
    font-weight: 600;
    text-transform: uppercase;

    &:before {
      display: none;
    }

    .ben-icon {
      font-size: 20px;
      margin-right: 8px;
    }
  }

  .ben-link:hover,
  .ben-active-link {
    color: $dark-primary-color;
    border-color: $dark-primary-color;
  }
}
