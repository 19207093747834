.ben-link {
  color: $primary-color;

  &:hover {
    color: $dark-primary-color;
    text-decoration: none;
    cursor: pointer;
  }
}

.ben-light-btn {
  color: $primary-color;
  background-color: $grey-color;
  line-height: 19px;
  padding: 6px 18px;
  text-align: center;
  border-radius: 6px;
  display: flex;
  align-items: center;
  display: flex;

  &:hover {
    color: $white-color;
    background-color: $primary-color;
    text-decoration: none;
    cursor: pointer;
  }

  &.ant-btn-link[disabled] {
    background-color: $disabled-color;
  }

  &.ant-btn-link:focus,
  &.ant-btn-link:active {
    color: $primary-color;
    background-color: $grey-color;
  }
}

.ben-edit-btn-controls {
  .ben-icon {
    font-size: 20px;
  }
}

.ben-divider {
  margin: 0 10px;
}

.ben-btn-burger {
  display: block;
  width: 29px;
  height: 23px;
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  padding: 3px;

  span {
    display: block;
    width: 100%;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: $dark-primary-color;
    border-radius: 1px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    &:first-child {
      transform-origin: 0% 0%;
    }

    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }

  &.active {
    span {
      opacity: 1;
      transform: rotate(45deg) translate(-5px, -10px);

      &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(-3px, 9px);
      }
    }
  }
}
