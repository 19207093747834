@import "common/assets/styles/base/colors";

.ben-error-indicator {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $yellow-2-color;
}

.ben-error-indicator-title {
  font-size: 1.7rem;
}
