// external styles
@import "custom-antd.css";

// own styles
@import "antd-custom/all";
@import "general/layout";
@import "general/text";
@import "base/colors";
@import "base/theme";
@import "base/variables";
@import "helper/mixins";
@import "utilities/all";
@import "components/all";

body {
  display: flex;
  height: auto;

  &.switch-scrolling-effect {
    width: 100% !important;
    overflow: visible !important;
  }
}

#root {
  width: 100%;
  display: flex;

  > div {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 100vh;
  }
}

b,
strong {
  font-weight: 600;
}

.ben-hidden {
  display: none;
}

.ben-scroll {
  overflow-y: scroll;
}

.ben-secondary-border-top {
  border-top: 1px solid $grey-default-color;
}

.ben-secondary-border-bottom {
  border-bottom: 1px solid $grey-default-color;
}

.ben-border-default {
  border: 2px solid $grey-default-color;
}

.ben-horisontal-divider {
  margin: 0 10px;

  &:before {
    content: " ";
    height: 1.15em;
    width: 2px;
    display: inline-block;
    background-color: $grey-default-color;
    vertical-align: sub;
  }
}

.ben-sidebar-container {
  display: flex;

  @include breakpoint(sm) {
    display: block;
  }

  .ben-sidebar {
    padding: 40px 0 0 20px;
    min-width: 220px;
    max-width: 220px;
    background: linear-gradient(90deg, $dark-blue-3-color 0%, $primary-color 100%);
    height: 100%;
  }

  .ben-content {
    padding-left: 236px;
    flex: auto;

    @include breakpoint(md) {
      padding-left: 0;
    }
  }
}

.ben-client-dashboard {
  position: relative;

  .ben-main-section-btn-holder {
    position: absolute;
    right: 0;
    top: 17px;

    @include breakpoint(sm) {
      position: static;
    }

    .ant-btn {
      @include breakpoint(sm) {
        width: 100%;
      }
    }
  }

  > .ant-alert {
    width: 60%;
  }
}

.ant-notification {
  margin-right: 15px;
}
