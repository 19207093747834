@import "common/assets/styles/base/colors";

.ben-client-card {
  padding: 20px;
  margin-bottom: 25px;
  height: 221px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: url("../../../../common/assets/images/bg-card.jpg");
  background-size: cover;
  color: $white-color;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .ben-alert {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
  }

  .ben-name {
    display: block;
    font-size: 22px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 25px;
    min-height: 60px;

    a {
      color: $white-color;
    }
  }

  .ben-requests {
    display: block;
    line-height: 20px;
  }
}
