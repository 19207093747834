.ant-select {
  color: $dark-primary-color;

  .ant-select-arrow {
    margin-top: -8px;

    .anticon {
      font-size: 18px;
      color: $primary-color;
    }
  }
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: $dark-primary-color;
}

.ant-select-dropdown-menu-item {
  padding-top: 9px;
  padding-bottom: 9px;
  color: $dark-primary-color;
  border-top: 1px solid $grey-5-color;

  &:first-child {
    border: none;
  }
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background: $grey-color;
}

.ant-select-dropdown {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  border: 1px solid $grey-5-color;
  z-index: 9;
}

.ant-select-selection--single {
  .ant-select-selection__rendered {
    margin-right: 28px;
  }
}
