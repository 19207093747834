@import "common/assets/styles/base/theme";

.ben-liquidity-holdings {
  table {
    .ant-table-thead > tr > th {
      padding-left: 0;
    }

    .ant-table-tbody > tr > td {
      padding-left: 0;
    }

    .ant-table-thead > tr > th:first-of-type,
    .ant-table-tbody > tr > td:first-of-type {
      padding-left: 7px;
    }
  }

  .collapse.ant-btn-sm {
    width: 20px;
    height: 20px;
    font-size: 10px;
    min-width: 20px;
    margin: 0 0 0 -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -8px;
    left: 50%;
    background-color: transparent;
    border: 0;

    i {
      top: 2px;
      position: relative;
    }
  }

  .ant-collapse {
    border: none;
    background-color: transparent;

    & > .ant-collapse-item.ant-collapse-no-arrow {
      & > .ant-collapse-header {
        padding: 0;
      }
    }
  }

  .ant-collapse-content {
    border: none;
    background-color: transparent;

    & > .ant-collapse-content-box {
      padding: 0;
    }
  }
}
