.ben-client-details {
  .ant-collapse {
    border: 0;
    background: none;

    .ant-collapse-header {
      cursor: default !important;
    }

    .ant-collapse-item.ant-collapse-no-arrow {
      .ant-collapse-header {
        min-height: 56px;
        border-bottom: 1px solid $border-color;
        padding: 0;
        overflow: hidden;

        .ben-header {
          color: $dark-primary-color;
          font-size: 20px;
          font-weight: 600;
          line-height: 25px;
          padding-bottom: 15px;
          float: left;
          max-width: 70%;
        }

        .ant-collapse-extra {
          padding-left: 10px;
          padding-bottom: 15px;
        }
      }

      .ant-collapse-content {
        border-top: 0;

        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }

  .collapse.ant-btn-sm {
    width: 20px;
    height: 20px;
    font-size: 10px;
    min-width: 20px;
    margin: 0 0 0 -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    left: 50%;
    background-color: transparent;
    border: 0;

    i {
      top: 2px;
      position: relative;
    }
  }

  .ben-text-row {
    align-items: center;
    justify-content: space-between;

    label {
      width: auto;
    }

    .ben-text-row-text {
      color: $text-color;

      min-width: 69%;
      max-width: 69%;
      width: 69%;

      @include breakpoint(sm) {
        min-width: 50%;
        max-width: 50%;
        width: 50%;
      }
    }
  }

  .ant-input {
    &[disabled] {
      border: 0;
      background-color: $grey-color;
      color: $text-color;
      opacity: 0.7;
    }
  }
}
