.ben-documents-content {
  .ben-documents-content-backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: transparent;
  }
}
