@import "common/assets/styles/base/theme";

.uploading-directory--document {
  min-height: 46px;
  border-bottom: 1px solid $border-color;
  align-items: center;
  padding-right: 8px;

  .ben-error-text {
    margin-bottom: 0;
  }
}
