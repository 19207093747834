@import "common/assets/styles/base/theme";
@import "common/assets/styles/base/variables";
@import "common/assets/styles/helper/mixins";

.signin-page-forms-wrapper {
  display: flex;
  flex-direction: row;

  @include breakpoint(md) {
    flex-direction: column;

    .signin-page-form-wrapper {
      margin-top: 2em;
    }
  }
}
