.ant-checkbox-inner {
  width: 20px;
  height: 20px;

  &::after {
    left: 26%;
  }
}

.ant-checkbox-wrapper {
  .ant-checkbox + span {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: $primary-color;
  }

  &.ant-checkbox-wrapper-disabled {
    .ant-checkbox + span {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

.ant-checkbox-inner {
  &:after {
    left: 33%;
  }
}
