.session-modal-message {
  .message-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-wrapper {
      justify-content: center;
      padding: 0 30px;
    }

    .message-content {
      > p:last-child {
        margin-bottom: 0;
      }
    }
  }

  & .ben-modal-content {
    padding-bottom: 23px;
  }
}
