$breakpoints: (
  // Extra small screen / phone
    "xs": 0,
  // Small screen / phone
    "sm": 576px,
  // Medium screen / tablet
    "md": 768px,
  // Large screen / desktop
    "lg": 992px,
  // Large / Extra large screen
    "xlg": 1024px
) !default;

$container-max-width: 960px;
