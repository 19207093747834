.asset-card-form {
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 18px;
    &[name="liquidity"] {
      padding-left: 12px;
    }
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
}
