.ben-required-field {
  label {
    &:after {
      @include transition;
      display: inline-block;
      margin-right: 4px;
      color: $light-blue-color;
      font-size: 14px;
      line-height: 1;
      content: "*";
    }
  }
}
