@import "common/assets/styles/base/colors";

.ben-text-row {
  line-height: 20px;
  padding: 17px 0 28px;
  position: relative;

  label {
    font-weight: 600;
    width: 200px;
    padding: 0 10px 0 0;
  }

  .ben-text-row-text {
    padding-left: 12px;
    color: $dark-primary-color;

    &:after {
      position: absolute;
      bottom: 6px;
      left: 0;
      clear: both;
      content: "";
      width: 100%;
      height: 1px;
      background: $border-color;
    }
  }
}

.ben-error-label,
.ben-error-text {
  color: $error-color;
}

.ben-required-label {
  color: $light-blue-color;

  a {
    color: $light-blue-color;
  }
}

.ben-required-text {
  border-bottom: 1px solid $border-color;

  p {
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.ben-highlighted-text {
  color: $blue-color;
}

.ben-important-text {
  font-weight: 600;
  text-decoration: underline;
}
