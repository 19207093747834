@import "common/assets/styles/base/theme";
@import "common/assets/styles/base/variables";
@import "common/assets/styles/helper/mixins";

.ben-card-content {
  padding: 27px 27px 20px;
  background-color: $white-color;
  border-radius: 3px;

  @include breakpoint(sm) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .ben-header {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    .ben-alert {
      margin-left: 10px;

      .ben-icon {
        vertical-align: -2px;
      }
    }

    .ben-card-content-title-elements {
      display: flex;
      > * {
        margin-left: 16px;

        @include breakpoint(sm) {
          margin-top: 16px;
        }

        &:first-child {
          @include breakpoint(sm) {
            margin-left: 0px;
          }
        }
      }
    }
  }

  h3 {
    color: $dark-primary-color;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    margin: 0;
  }

  h4 {
    margin-bottom: 6px;
    font-weight: 600;
    line-height: 18px;
  }
}
