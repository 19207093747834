@import "common/assets/styles/base/theme";

.holdings-documents-modal {
  .holdings-documents-content {
    height: 300px;
    height: 30vh;
    overflow-y: scroll;
    padding: 0 10px;

    .ben-document-title {
      padding-left: 10px;
    }
  }

  .info-message {
    font-size: 14px;
    font-weight: 600;
    color: $dark-primary-color;
    padding: 10px 0;
    margin-bottom: 0;
  }
}
