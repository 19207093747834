@import "common/assets/styles/base/theme";

.ben-combined-data {
  display: block;

  > span {
    display: block;
    margin-bottom: 5px;
  }
}
