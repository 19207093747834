@import "common/assets/styles/base/theme";

.upload-directory--documents-list {
  .info-message {
    font-size: 14px;
    font-weight: 600;
    color: $dark-primary-color;
    padding: 1em 0;
  }
}
