@import "common/assets/styles/base/theme";

.ben-card-help {
  &-info {
    padding: 0 0 25px;
    border-bottom: 1px solid $border-color;

    .ben-icon {
      margin-right: 10px;
      vertical-align: -2px;
    }
  }

  &-subtitle {
    color: $primary-color;
  }

  &-date {
    display: block;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    padding: 6px 0 0 24px;
  }

  &-content {
    padding: 25px 0;
  }
}
