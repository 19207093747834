$grey-default-color: #ddd;
$grey-text-default-color: #686868;
$blue-default-color: #1890ff;
$error-default-color: #f5222d;

$white-color: #fff;
$grey-color: #f0f2f5;
$grey-2-color: #d8d8d8;
$grey-3-color: #f0f0f0;
$grey-4-color: #e8e8e8;
$grey-5-color: #c9cbcd;
$grey-6-color: #eee;
$grey-7-color: #00000040;
$light-grey-color: #e1e1e1;
$dark-grey-color: #4d4d4d;
$grey-blue-color: #dce9f1;
$yellow-color: #ffbf00;
$yellow-2-color: #c78f22;
$dark-blue-color: #035073;
$dark-blue-2-color: #003953;
$dark-blue-3-color: #045074;
$light-blue-color: #80d1e4;
$blue-color: #0a87a5;
$light-black-color: #4a4a4a;
$black-color: #1d1d1b;
$black-2-color: #484848;
$red-color: #e6172c;

$status-in-verification-color: $light-blue-color;
$status-in-underwriting-color: $dark-blue-color;
$status-in-proposal-color: #bce8a6;
$status-in-contract-color: #5ec12d;
$status-signed-color: #0a853d;
$status-funded-color: #0a853d;
$status-cancelled-color: $red-color;
