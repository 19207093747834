@import "common/assets/styles/base/colors";

$dark-primary-color: $dark-blue-color;
$primary-color: $blue-color;
$bg-body: $grey-color;
$border-color: $light-grey-color;
$text-color: $light-black-color;
$error-color: $red-color;
$info-color: $yellow-color;
$disabled-color: $grey-7-color;

.ben-primary-text-color {
  color: $primary-color;
}

.ben-dark-primary-text-color {
  color: $dark-primary-color;
}
