.terms-agreement-modal-body {
  height: 500px;
  height: 60vh;
  margin-top: 15px;
  overflow-y: scroll;

  .agreement-textIndent {
    text-indent: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .section-Margin {
    margin: 0px 10px 0px 21px;
  }
}
