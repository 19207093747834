@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/variables";
@import "common/assets/styles/base/theme";
@import "common/assets/styles/helper/mixins";

.ben-header-main {
  position: fixed;
  z-index: 1001;
  width: 100%;
  background: $white-color;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

  .ben-container {
    display: flex;
    min-height: 115px;
    padding-top: 15px;

    > div {
      width: 100%;
    }

    @include breakpoint(sm) {
      min-height: 90px;
    }
  }
}

.ben-main-container {
  padding-top: 125px;
  flex: auto;
  min-height: 640px;

  @include breakpoint(sm) {
    padding-top: 115px;
    min-height: 480px;
  }
}

.ben-header-main-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex: auto;

  @include breakpoint(sm) {
    display: block;
  }

  .ben-logo {
    margin-bottom: 18px;
  }
}

.ben-header-desktop {
  display: flex;
  flex-direction: column;
}

.ben-header-main-mobile {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 16px;
}

.ben-header-menu-drawer {
  @include breakpoint(md-up) {
    display: none !important;
  }

  &.ant-drawer-top {
    top: 90px;

    .ant-drawer-content-wrapper {
      height: auto !important;
      max-height: calc(100vh - 90px);
      overflow-y: auto;
    }

    .ant-drawer-content {
      overflow-y: auto;
    }

    .ant-drawer-body {
      padding: 20px 15px;
    }

    .ben-greetings {
      padding-bottom: 20px;
      border-bottom: 1px solid $border-color;
    }

    .ben-account {
      padding: 20px 0;
      line-height: 20px;
      border-bottom: 1px solid $border-color;

      .ben-divider {
        display: none;
      }
    }

    .ben-account-number {
      display: block;
    }

    .ant-tabs-nav {
      width: 100%;
      margin-bottom: 20px;

      .ant-tabs-tab {
        display: block;
        line-height: 50px;
        margin-right: 0;
        width: 100%;
        border-bottom: 1px solid $border-color;
        border-top: 1px solid transparent;
        transition: color 0.2s;
      }

      .ben-active-link {
        border-top: 3px solid transparent;
        border-bottom: 3px solid $dark-primary-color;
        line-height: 46px;
      }
    }

    #benBtnSignout {
      width: 100%;
    }
  }
}

.ant-spin-nested-loading > div > .ant-spin.ben-spinner-load-settings {
  background-color: $grey-color;
  max-height: 100%;

  .ant-spin-dot {
    position: fixed;
  }
}
