@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon.eot?b0peo3");
  src: url("../../fonts/icomoon.eot?b0peo3#iefix") format("embedded-opentype"),
    url("../../fonts/icomoon.ttf?b0peo3") format("truetype"),
    url("../../fonts/icomoon.woff?b0peo3") format("woff"),
    url("../../fonts/icomoon.svg?b0peo3#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-folder:before {
  content: "\e91f";
}
.icon-invite-user:before {
  content: "\e91e";
}
.icon-account:before {
  content: "\e900";
}
.icon-archive:before {
  content: "\e901";
}
.icon-attention .path1:before {
  content: "\e902";
  color: rgb(255, 191, 0);
}
.icon-attention .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-attention .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-attention-invert .path1:before {
  content: "\e905";
  color: rgb(255, 255, 255);
}
.icon-attention-invert .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 191, 0);
}
.icon-attention-invert .path3:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(255, 191, 0);
}
.icon-cancel:before {
  content: "\e908";
}
.icon-complete .path1:before {
  content: "\e909";
  color: rgb(10, 133, 61);
}
.icon-complete .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-dashboard:before {
  content: "\e90b";
}
.icon-declined .path1:before {
  content: "\e90c";
  color: rgb(230, 23, 44);
}
.icon-declined .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-declined .path3:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-download:before {
  content: "\e90f";
}
.icon-edit:before {
  content: "\e910";
}
.icon-info:before {
  content: "\e911";
}
.icon-messages:before {
  content: "\e912";
}
.icon-not-available:before {
  content: "\e913";
}
.icon-pending .path1:before {
  content: "\e914";
  color: rgb(128, 209, 228);
}
.icon-pending .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-question:before {
  content: "\e916";
}
.icon-review:before {
  content: "\e917";
}
.icon-save:before {
  content: "\e918";
}
.icon-settings:before {
  content: "\e919";
}
.icon-share:before {
  content: "\e91a";
}
.icon-trash:before {
  content: "\e91b";
}
.icon-upload:before {
  content: "\e91c";
}
.icon-view:before {
  content: "\e91d";
}
.icon-books:before {
  content: "\e920";
}
