@import "common/assets/styles/base/theme";
@import "common/assets/styles/base/variables";
@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/antd-custom/buttons";

.ben-alert-message {
  .ant-alert-warning {
    background: $info-color;
    padding: 25px 57px 25px 57px !important;
    border-radius: 3px;
    border: 0;

    @include breakpoint(sm) {
      padding: 13px 40px 13px 45px !important;
    }
  }

  .ant-alert-message {
    color: $white-color;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .ant-alert-icon {
    top: 50%;
    left: 27px;
    margin-top: -9px;

    @include breakpoint(sm) {
      left: 15px;
    }
  }

  .ant-alert-close-icon {
    @extend .ben-btn-reset;
    top: 50%;
    right: 22px;
    margin-top: -11px;

    @include breakpoint(sm) {
      right: 11px;
    }

    .ant-alert-close-text {
      color: $white-color;
    }
  }
}
