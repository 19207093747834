.ant-progress-status-active {
  .ant-progress-bg {
    animation: progress-bar 2s;
  }
}

@keyframes progress-bar {
  0% {
    width: 0;
  }
}
