@import "common/assets/styles/base/theme";

.ben-greetings {
  display: flex;
  align-items: center;
  padding: 0 12px 0 0;

  .ben-greetings-name {
    font-size: 18px;
    line-height: 14px;
    text-align: right;
    color: $dark-grey-color;
  }
}
