@import "common/assets/styles/base/theme";
@import "common/assets/styles/base/colors";

.ben-sidebar-menu {
  .ant-anchor-wrapper {
    background-color: transparent;
    overflow: visible;
    margin-left: 0;
    padding-left: 0;
  }

  .ant-anchor {
    background: none;
    border: 0;
    width: 100% !important;
    padding-left: 0px;

    .ant-anchor-ink {
      display: none;
    }

    .ant-anchor-link {
      background: $dark-blue-2-color;
      margin-bottom: 5px !important;
      width: 100% !important;
      height: auto;
      padding: 5px 5px 5px 20px !important;
      line-height: 20px;
      text-transform: uppercase;
      white-space: inherit;

      .ant-anchor-link-title {
        text-overflow: unset;
        overflow: visible;
        white-space: normal;

        &.ant-anchor-link-title-active {
          font-weight: 900;
          background: $dark-blue-2-color;
        }
      }

      a {
        color: $white-color;

        &:before {
          display: none;
        }

        &:hover {
          font-weight: 900;
          text-decoration: none;
        }
      }
    }
  }
}
