.ben-form-holder {
  border-top: 1px solid $border-color;
  padding: 30px 0 20px;

  .ant-form {
    textarea.ant-input {
      min-height: 104px;
    }
  }
}

.ben-form-editable-mode {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;

  &.account-edit {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-shrink: 0;
    }
  }

  .institution-form {
    display: flex;
    flex-direction: column;
  }
}

.ben-form {
  margin: 40px;
}

.ben-form-item {
  .ant-form-item-label {
    width: 200px;
  }
}

.ben-form-label-field {
  &.ant-form-item {
    padding: 7px 0 18px;
    margin-bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    &:before,
    &:after {
      display: none;
    }

    .ant-form-item-control-wrapper {
      min-width: 69%;
      max-width: 69%;
      width: 69%;

      @include breakpoint(sm) {
        min-width: 50%;
        max-width: 50%;
        width: 50%;
      }
    }
  }
}

.ben-form-field-col-2 {
  .ben-form-label-field {
    &.ant-form-item {
      .ant-form-item-control-wrapper {
        min-width: 50%;
        max-width: 50%;
        width: 50%;
      }
    }
  }
}

.ben-form-cols-2 {
  column-count: 2;
  column-gap: 5px;
  margin: 0 -14px 0 -15px !important;
  position: relative;

  @include breakpoint(sm) {
    column-count: 1;
  }

  > div {
    padding: 0 14px 0 15px;
    break-inside: avoid-column;
  }
}

.ben-form-actions {
  @include breakpoint(md) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.ben-file-name-wrapper {
  .ant-input {
    padding-right: 40px;
  }

  .ben-icon {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    color: $primary-color;
  }
}

.ben-file-upload-wrapper {
  .ben-document-content {
    &.ben-document-content-small {
      margin-top: -1.5rem;
      margin-bottom: 1.5rem;
      padding: 10px 0;
    }

    .ben-document-title {
      color: $text-color;
      &.ben-document-title-error {
        color: $error-color;
      }
    }
  }

  .ant-col-md-1 span.ben-icon {
    font-size: 16px !important;
  }

  .ben-document-actions .ant-btn.ant-btn-link {
    margin-top: -7px;
    margin-bottom: -7px;
  }

  .ben-form-label-field.ant-form-item .ant-form-item-control-wrapper {
    @include breakpoint(sm) {
      min-width: 60%;
      max-width: 60%;
      width: 60%;
    }
  }
}
