@import "common/assets/styles/base/theme";

.ben-account-header {
  padding: 0 0 10px;
  margin-bottom: 8px;
  border-bottom: 1px solid $border-color;
  display: flex;
  justify-content: space-between;

  .ben-greetings {
    font-size: 24px;
    color: $black-2-color;
  }
}

.ben-account {
  margin-left: auto;
  font-size: 12px;
  line-height: 30px;

  .ben-divider {
    margin: 0 5px;
  }
}

.ben-account-number {
  color: $dark-primary-color;
}
