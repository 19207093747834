@import "common/assets/styles/base/theme";

.ben-document-content {
  padding: 26px 0;
  border-bottom: 1px solid $border-color;

  &.ben-document-content-with-dragger {
    &.ben-document-content-small {
      padding: 0;
    }

    > .ant-col {
      padding: 15px 0;
    }
  }

  &.ben-document-content-small {
    padding: 15px 0;
  }

  .ben-document-title {
    color: $primary-color;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 14px;

    &.ben-document-title-error {
      color: $error-color;
    }
  }

  p {
    font-size: 13px;
    line-height: 14px;
    margin-bottom: 0;
  }

  .ben-document-status-img {
    vertical-align: text-top;
  }

  .ben-document-actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    .ant-btn.ant-btn-link {
      padding: 0;
    }
  }
}
