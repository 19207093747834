@import "common/assets/styles/base/theme";

.instructional-verbiage-wrapper {
  position: relative;
  display: flex;
  .instructional-verbiage-info-trigger {
    padding: 0 10px;
    background-color: transparent;
    color: $dark-primary-color;
    height: 25px;

    i {
      font-size: 22px;
      height: 22px;
    }
  }
}
