@import "common/assets/styles/base/theme";
@import "common/assets/styles/base/variables";
@import "common/assets/styles/helper/mixins";

.external-account-type-radio {
  .external-account-type-radio-group {
    display: block;
  }

  .ant-radio-wrapper {
    line-height: 30px;

    @include breakpoint(lg) {
      display: block;
    }
  }
}
