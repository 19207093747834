@import "common/assets/styles/base/variables";
@import "common/assets/styles/helper/mixins";

.request-statuses-list {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;

  @include breakpoint(lg) {
    flex-direction: column;
  }
}
