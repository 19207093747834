// Transformation
.ben-text-lowercase {
  text-transform: lowercase !important;
}

.ben-text-uppercase {
  text-transform: uppercase !important;
}

.ben-text-capitalize {
  text-transform: capitalize !important;
}

// Alignment
.ben-text-left {
  text-align: left !important;
}

.ben-text-right {
  text-align: right !important;
}

.ben-text-center {
  text-align: center !important;
}

.ben-word-break-all {
  word-break: break-all;
}
