@import "common/assets/styles/base/theme";

.ben-invite-item {
  padding: 20px 0;
  align-items: center;
  border-bottom: 1px solid $border-color;

  &:first-child {
    border-top: 1px solid $border-color;
  }

  &-title {
    display: block;
    color: $primary-color;
  }
}
