@import "common/assets/styles/base/colors";

.ben-holding-content {
  position: relative;
  margin-top: 16px;

  &.ben-mb-4 {
    margin-bottom: 16px !important;
  }

  .ben-holding-backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: transparent;
  }

  .holding-fund-name {
    margin-bottom: 30px;
    padding: 0 0 10px;
    color: $dark-blue-color;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    border-bottom: 1px solid $grey-5-color;
  }
}
