.ant-modal-wrap,
.ant-modal-mask {
  z-index: 1002;
}

.ant-modal-content {
  border-radius: 3px;
}

.ben-modal-container {
  .ant-modal-header {
    padding: 30px 0;
    margin: 0 30px;

    .ant-modal-title {
      font-size: 20px;
      color: $dark-primary-color;
      font-weight: 600;
      line-height: 25px;
    }
  }

  .ant-modal-body {
    padding: 0 30px 30px;
  }

  .ant-modal-close {
    top: 28px;
    right: 22px;

    .ant-modal-close-x {
      width: 30px;
      height: 30px;
      line-height: 30px;

      .anticon {
        color: $primary-color;
        font-size: 20px;
      }
    }
  }

  .ben-modal-content {
    padding-bottom: 16px;
    padding-top: 23px;
    margin-bottom: 30px;
    border-bottom: 1px solid $grey-4-color;

    h4 {
      color: $text-color;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 24px;
    }

    p {
      line-height: 18px;
    }
  }
}

.ant-modal {
  @include breakpoint(sm) {
    margin-left: 15px;
    margin-right: 15px;
    width: auto !important;
  }
}
