@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/theme";

.ben-row-data {
  border-bottom: 1px solid $border-color;
  padding: 0 0 6px;

  h4 {
    margin: 16px 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  p {
    color: $black-color;
    margin-bottom: 11px;
    font-size: 12px;
    line-height: 15px;
  }
}
