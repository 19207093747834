@import "common/assets/styles/base/theme";

.ben-breadcrumb {
  display: flex;
  white-space: nowrap;

  .ben-breadcrumb-item {
    font-size: 12px;
    line-height: 30px;
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
  }

  a.ben-breadcrumb-item {
    color: $primary-color;
  }

  .ant-breadcrumb {
    flex-flow: wrap;

    .anticon.ben-breadcrumb-icon {
      color: $primary-color;
      margin-right: 5px;
    }
  }
}

.ben-locale-not-default {
  .ant-breadcrumb {
    & > :nth-child(2) {
      .ben-breadcrumb-item {
        max-width: 80px;
      }
    }
  }
}
