.ant-table {
  table {
    .ant-table-thead {
      > tr {
        > th {
          background-color: transparent;
          color: $dark-primary-color;
          font-weight: 600;
          line-height: 18px;
          border-top: 1px solid $border-color;
          padding: 7px 10px;
        }
      }
    }

    .ant-table-tbody {
      > tr {
        > td {
          border-bottom: 1px solid $border-color;
          padding: 16px 10px;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          vertical-align: top;
        }
      }
    }

    .ben-row-data {
      h4 {
        margin-top: 0;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  @include breakpoint(sm) {
    &-thead {
      display: none;
    }

    &-thead > tr,
    &-tbody > tr {
      &:hover {
        td {
          background: none !important;
        }
      }

      > th,
      > td {
        display: block;
        width: auto !important;
        border: none;
        padding: 0 1rem;
        font-size: 1.1rem;

        &:first-child {
          .ben-row-data {
            padding-bottom: 6px;

            h4 {
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 7px !important;
            }
          }
        }

        &:last-child {
          border-bottom: 1px solid $grey-6-color;
        }
      }

      td {
        padding: 10px 0 !important;
        text-align: right;
        font-size: 12px !important;
        line-height: 16px !important;

        &:first-child {
          border-top: 1px solid $border-color;
          padding-top: 22px !important;
          padding-bottom: 18px !important;
          text-align: left;
        }

        &:before {
          content: attr(data-label);
          float: left;
          color: $dark-primary-color;
        }
      }
    }

    .ben-row-data {
      padding-bottom: 0;

      h4 {
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 0 !important;
        color: $text-color;
      }
    }
  }
}
