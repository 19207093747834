@import "common/assets/styles/base/theme";

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background-color: transparent;
}

.categorized-documents {
  position: relative;

  .info-message {
    font-size: 14px;
    font-weight: 600;
    color: $dark-primary-color;
    padding: 16px 10px 0;
    margin-bottom: 0;
  }

  .categorized-documents-table-header {
    padding: 16px 10px 0;

    h4 {
      font-weight: 500;
    }
  }

  .categorized-documents-table-content {
    > * {
      padding: 5px 10px 0;
    }
    > :nth-child(even) {
      background-color: $grey-color;
    }

    > :nth-child(odd) {
      background-color: $grey-blue-color;
    }
  }
}
