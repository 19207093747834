@import "common/assets/styles/base/variables";
@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";

.ant-btn {
  font-weight: bold;

  &.ant-btn-lg {
    @include breakpoint(md) {
      height: 32px;
      font-size: 14px;
      line-height: 32px;
    }
  }
}

.ant-btn-link {
  font-weight: normal;
}

.ant-btn-round {
  padding-left: 24px;
  padding-right: 24px;
}

.ant-btn-link {
  font-weight: normal;
}

.ben-btn-reset {
  padding: 0;
  border: 0;
  line-height: 1.2;
  height: auto;
  background: none;

  .ant-btn {
    padding: 0;
    border: 0;
    line-height: 1;
    height: auto;
  }
}

.ben-dragged-content {
  .ant-upload.ant-upload-btn {
    padding: 0;
  }

  .ben-document-title-draggable-info {
    opacity: 1;
    padding: 0;
    position: absolute !important;
    left: 0;
    width: 100%;
    text-align: center;
    width: 100%;
    bottom: 0;
    color: $light-blue-color;
    font-size: 12px;
    transition: opacity 0.5s 0s;
  }

  &:not(.ant-upload-drag-hover) {
    .ben-document-title-draggable-info {
      opacity: 0;
      transition: opacity 0.5s 0s;
    }
  }

  &:hover {
    .ben-document-title-draggable-info {
      opacity: 1;
      transition: opacity 0.5s 0s;
    }
  }
}

.ben-document-content.ben-document-content-small {
  .ben-dragged-wrapper.ant-col {
    padding: 2px;
    overflow: hidden;

    .ben-uploading-text {
      padding: 13px 0;
    }

    .ben-document-title {
      padding: 13px 0;
      display: inline-block;
      margin-bottom: 0;
    }

    .ant-upload.ant-upload-drag {
      text-align: left;

      .ben-document-title-drag-icon {
        opacity: 1;
        margin-left: 8px;
        margin-right: 8px;
        transition: margin-left 0.5s 0s;
      }

      .ben-document-title-draggable-info {
        opacity: 1;
        padding: 0;
        position: absolute;
        left: 28px;
        width: 100%;
        text-align: center;
        width: 100%;
        text-align: center;
        bottom: 0;
        color: $light-blue-color;
        font-size: 12px;
        transition: opacity 0.5s 0s;
      }

      &:not(.ant-upload-drag-hover) {
        background: transparent;
        border: 1px solid transparent;

        .ben-document-title-drag-icon {
          opacity: 0;
          margin-left: -28px;
          transition: margin-left 0.5s 0s;
        }

        .ben-document-title-draggable-info {
          opacity: 0;
          transition: opacity 0.5s 0s;
        }
      }

      &:hover {
        background: #fafafa;
        border: 1px dashed #c9cbcd;

        .ben-document-title-drag-icon {
          opacity: 1;
          margin-left: 8px;
          margin-right: 8px;
          transition: margin-left 0.5s 0s;
        }

        .ben-document-title-draggable-info {
          opacity: 1;
          transition: opacity 0.5s 0s;
        }
      }
    }
  }
}
