@import "common/assets/styles/base/theme";

.ben-required-text {
  &.ben-form-top-validation {
    transition: max-height 0.3s ease-out;
    border-bottom: none;
    border-top: 1px solid $border-color;
    max-height: 0;
    overflow: hidden;

    &.ben-bottom-border {
      border-top: none;

      &.ben-form-top-validation-show {
        border-bottom: 1px solid $border-color;
      }
    }

    &.ben-form-top-validation-show {
      max-height: 54px;
      transition: max-height 0.3s ease-in;
    }

    .ben-required-label {
      color: $error-color;
    }
  }
}
