@import "common/assets/styles/base/variables";
@import "common/assets/styles/helper/mixins";

.verify-investor-modal-container {
  overflow: hidden;
  .verify-investor-modal-body {
    height: 500px;
    height: 70vh;

    @include breakpoint(md) {
      overflow-y: scroll;
      height: 400px;
      height: 60vh;
    }
  }

  .verify-investor-iframe {
    height: 100%;
  }
}

.accreditation-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  p {
    margin-bottom: 2em;
  }
}

.accreditation-preview-mobile {
  display: none;
  @include breakpoint(md) {
    display: block;
  }
}
