@import "common/assets/styles/base/theme";

.liquidity-asset-info {
  &.ant-collapse {
    .ant-collapse-item {
      &.ant-collapse-no-arrow {
        & > .ant-collapse-header {
          padding: 0 10px;
          border-radius: 3px;
          transition: all 0.3s;
        }
      }
      .ant-collapse-header {
        &:hover {
          background-color: $grey-color;
          transition: all 0.3s;
          h4,
          p {
            color: $primary-color;
            transition: all 0.3s;
          }
        }
      }

      &.ant-collapse-item-active {
        .ant-collapse-header {
          background-color: $grey-color;
          h4,
          p {
            color: $primary-color;
          }
        }
      }
    }
  }
}
