.ben-select.ant-select {
  .ant-select-arrow {
    margin-top: -2px;
  }

  &.ant-select-open {
    .ant-select-arrow {
      transform: rotate(180deg);
      transition: all 0.3s;
    }
  }
  &.ant-select-enabled {
    .ant-select-arrow {
      transition: all 0.3s;
    }
  }
}
