@import "common/assets/styles/base/variables";
@import "common/assets/styles/helper/mixins";

.ben-user-profile {
  .ben-text-row {
    align-items: center;
    justify-content: space-between;

    label {
      width: auto;
    }

    .ben-text-row-text {
      min-width: 50%;
      max-width: 50%;
      width: 50%;
    }
  }

  .ben-client-details {
    .ben-text-row {
      .ben-text-row-text {
        min-width: 50%;
        max-width: 50%;
        width: 50%;
      }
    }
  }

  .ben-form-label-field {
    &.ant-form-item {
      .ant-form-item-control-wrapper {
        min-width: 50%;
        max-width: 50%;
        width: 50%;
      }
    }
  }

  .ant-card-head-wrapper {
    min-height: 64px;
  }

  // TODO: hidden edit buttons temporary
  .ant-collapse-extra {
    @include breakpoint(md) {
      display: none;
    }
  }
}
