@import "common/assets/styles/base/theme";
@import "common/assets/styles/base/variables";
@import "common/assets/styles/helper/mixins";

.ben-footer-main {
  padding: 58px 0 30px;
  background: $dark-primary-color;
  color: $white-color;
  position: relative;
  z-index: 2;

  .ben-logo {
    margin-bottom: 40px;
  }

  p {
    margin-bottom: 30px;
    font-size: 16px;
  }

  .ben-footer-cols {
    .ant-col {
      &:first-child {
        @include breakpoint(sm) {
          padding-bottom: 15px;
        }
      }
    }

    p {
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 15px;
    }
  }

  .footer-privacy-terms-info {
    text-align: right;

    @include breakpoint(sm) {
      text-align: left;
    }
  }
}
