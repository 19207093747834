.ben-d-flex {
  display: flex !important;
}

.ben-d-none {
  display: none !important;
}

.ben-d-block {
  display: block !important;
}

.ben-d-xs-none {
  @include breakpoint(sm) {
    display: none !important;
  }
}

.ben-d-sm-none {
  @include breakpoint(md) {
    display: none !important;
  }
}

.ben-d-md-none {
  @include breakpoint(lg) {
    display: none !important;
  }
}

.ben-d-lg-none {
  @include breakpoint(xlg) {
    display: none !important;
  }
}

.ben-d-sm-block {
  @include breakpoint(md) {
    display: block !important;
  }
}

.ben-d-md-block {
  @include breakpoint(lg) {
    display: block !important;
  }
}
