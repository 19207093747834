@import "common/assets/styles/base/theme";

.ben-shared-by-item {
  min-height: 50px;
  align-items: center;
  display: flex;
  border-bottom: 1px solid $border-color;
  color: $primary-color;
  line-height: 16px;
}
