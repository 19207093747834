@import "common/assets/styles/base/variables";
@import "common/assets/styles/helper/mixins";

.ben-login-footer {
  padding: 0 12px;

  &.ben-text-right {
    @include breakpoint(sm) {
      text-align: left !important;
    }
  }
}
