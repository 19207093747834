@import "common/assets/styles/base/colors";

@import "common/assets/styles/base/variables";
@import "common/assets/styles/helper/mixins";

.request-statuses-item {
  display: flex;
  font-size: 15px;
  line-height: 19px;
  padding: 6px;
  text-align: center;
  color: $blue-color;
  background-color: $grey-color;
  border-radius: 6px;
  width: calc(20% - 3px);
  justify-content: center;

  &:hover,
  &:focus,
  &:active {
    background-color: $grey-color;
  }

  @include breakpoint(lg) {
    width: 100%;
    margin-bottom: 3px;
  }

  &.cancel-final {
    background-color: $dark-blue-3-color;
    color: $white-color;
  }

  &.cancel-base {
    background-color: $blue-color;
    color: $white-color;
    opacity: 0.6;
  }

  &.active {
    background-color: $blue-color;
    color: $white-color;
  }
}
