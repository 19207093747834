.ant-calendar-input-wrap {
  height: 40px;
  padding-top: 7px;
  padding-left: 11px;

  .ant-calendar-input {
    height: 30px;
  }
}

.ant-calendar {
  width: 286px;
}
