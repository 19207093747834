.ant-form-item-control {
  line-height: 40px;
}

.ant-form-item-label {
  display: flex;
  line-height: 20px;
  text-align: left;
  min-height: 40px;
  align-items: center;
  padding: 0 10px 0 0 !important;

  > label {
    color: $text-color;
    font-weight: 600;

    &:after {
      display: none;
    }
  }
}

.ant-form-explain {
  font-size: 12px;
  min-height: 18px;
  line-height: 16px;
  position: absolute;
  bottom: -21px;
  left: 0;
  right: 0;
  margin: 0 12px;

  .ben-edge & {
    line-height: 15px;
  }

  @include breakpoint(md) {
    font-size: 10px;
    bottom: -20px;
    line-height: 9px;
  }
}

.has-error {
  .ant-form-explain,
  .ant-form-split {
    color: $error-color;
  }
}

.has-error {
  .ant-input {
    color: $error-color;

    &:focus {
      box-shadow: 0 0 2px 2px rgba(245, 34, 45, 0.5);
    }
  }
}

.ant-input {
  @include breakpoint(sm) {
    font-size: 16px;
  }

  &:focus {
    box-shadow: 0 0 2px 2px rgba(10, 135, 165, 0.5);
  }
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  box-shadow: 0 0 2px 2px rgba(10, 135, 165, 0.5);
}

.ant-form-item-with-help {
  .ant-form-item-label {
    color: $error-color;

    label {
      @include transition;
      color: $error-color;

      &:after {
        color: $error-color;
      }
    }
  }
}

.ant-form-horizontal {
  .ant-form-item {
    margin-bottom: 18px;
  }
}

.ant-input-number {
  width: 100%;
}
