@import "common/assets/styles/base/theme";

.simple-document-content {
  padding: 15px 0;
  border-bottom: none;

  .ben-document-title {
    color: $primary-color;
    font-size: 13px;
    margin-bottom: 7px;
    display: flex;
  }

  .simple-document-actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .simple-document-date {
    color: $primary-color;
    font-size: 13px;
    margin-bottom: 7px;
    display: flex;
  }
}
