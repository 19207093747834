@import "common/assets/styles/base/theme";

.ant-collapse {
  > .ant-collapse-item {
    border: 0;

    > .ant-collapse-header {
      color: $text-color;
    }
  }
}
