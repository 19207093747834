@import "common/assets/styles/base/variables";
@import "common/assets/styles/base/colors";

.ben-container {
  max-width: $container-max-width;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.ben-vertical {
  display: flex;
  flex-direction: column;
}

.ben-horisontal {
  display: flex;
  flex-direction: row;
}

.ben-margin-vertical-20 {
  margin: 20px 0;
}

.ben-public-container {
  max-width: 720px;
  min-height: 600px;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  position: relative;
}

.ben-page-container-with-nav {
  display: flex;
  margin: 0 auto 20px;
  padding-left: 40px;
  width: 1050px;

  .ben-title {
    font-size: 24px;
    font-weight: 600;
    padding-top: 40px;
    color: $black-2-color;
  }
}
