@import "common/assets/styles/base/theme";
@import "common/assets/styles/base/variables";
@import "common/assets/styles/helper/mixins";

.ben-tabs-content {
  .ant-tabs.ant-tabs-card {
    .ant-tabs-card-bar {
      margin: 0 0 24px;
      border-bottom: 1px solid $dark-primary-color;

      .ant-tabs-nav-container {
        height: 34px;
      }

      .ant-tabs-nav {
        @include breakpoint(sm) {
          width: 100%;
        }
      }

      .ant-tabs-tab {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        min-width: 158px;
        color: $primary-color;
        background-color: $grey-3-color;
        border-radius: 3px 3px 0 0;
        border: 1px solid $grey-3-color;
        border-bottom: 1px solid $dark-primary-color;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        text-transform: inherit;

        &:last-child {
          @include breakpoint(sm) {
            float: right;
          }
        }

        @include breakpoint(sm) {
          min-width: 49.75%;
          float: left;
          margin-right: 0;
        }

        &.ant-tabs-tab-active {
          color: $white-color;
          background-color: $dark-primary-color;
        }
      }
    }
  }
}
